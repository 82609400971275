<template>
  <v-card v-bind="$attrs" :class="spacingClass">
    <v-card-title v-if="$slots.title" :class="[titleClass, 'collapsable-title']" data-testid="backoffice-area-title" @click="toggleCollapsable">
      <slot name="title" />
      <v-icon class="ml-auto">{{ isCollapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon>
    </v-card-title>
    <v-card-text v-show="!isCollapsed" data-testid="backoffice-area-content">
      <slot name="content" />
    </v-card-text>
    <v-card-actions v-if="$slots.actions && !isCollapsed" data-testid="backoffice-area-actions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "BackofficeArea",

  inject: {
    containerState: {
      default: () => ({ allShouldBeCollapsed: false }),
    },
  },

  props: {
    titleClass: {
      type: String,
      default: "",
    },
    spacingClass: {
      type: String,
      default: "mb-6",
    },
    collapsed: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      internalCollapsed: this.collapsed,
      independentState: false,
    }
  },

  computed: {
    isCollapsed() {
      if (this.independentState) {
        return this.internalCollapsed
      }
      return this.containerState.allShouldBeCollapsed
    },
  },

  watch: {
    "containerState.allShouldBeCollapsed": {
      handler(newVal) {
        if (!this.independentState) {
          this.internalCollapsed = newVal
        }
      },
      immediate: true,
    },
  },

  methods: {
    toggleCollapsable() {
      this.internalCollapsed = !this.internalCollapsed

      this.independentState = this.internalCollapsed !== this.containerState.allShouldBeCollapsed
    },
  },
}
</script>

<style scoped>
.collapsable-title {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}
</style>
